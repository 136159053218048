export default class WclSidebar {

  constructor(opts={}) {
    this.prevValue = ""
  }

  supplyModule(selector) {
    /* Sidebar toggle behaviour */
    // console.log("sidebar apply", {selector, t: this});
    selector.on("click", () => {
      // appstack系で適用されている場合はパスさせる為、少し時間をおいてから実行する
      setTimeout(() => {this.sidebarToggle()}, 10);
    });
  }

  sidebarToggle() {
    const sidebar = $(".sidebar");
    const wasToggledByPureEvent = sidebar.hasClass("toggled");

    if ((wasToggledByPureEvent && this.prevValue === "")
      || (!wasToggledByPureEvent && this.prevValue === "toggled")) {

      // appstack系で適用されているかは、自身の保持してるステータスと差異があるかをチェックして行う
      // console.log("already toggled", {sidebar, wasToggledByPureEvent, this: this, prevValue: this.prevValue});
      this.prevValue = (this.prevValue === "") ? "toggled" : "";
      return;
    }

    // console.log("toggle", {sidebar, wasToggledByPureEvent, this: this, prevValue: this.prevValue});
    this.prevValue = (this.prevValue === "") ? "toggled" : "";
    sidebar.toggleClass("toggled")
      // Triger resize after animation
      .one("transitionend", function() {
        setTimeout(function() {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      });
  }

}
