export default class WclSupplyer {
  constructor(opts={}) {
  }

  createAttrKey(base) {
    const attrKey = `_wcl-${base}-supply`
    return attrKey
  }

  supply(selectorQuery, module, opts={
    delay: null,
    checkAppliedExtends: null,
    attrKey: null,
  }) {
    // ブラウザバックされると2回dataTable化されてしまったりするため
    // apply済みでない場合にapplyを行う

    // console.log("module");
    const selectors = $(selectorQuery);
    const attrKey = this.createAttrKey(opts.attrKey || selectorQuery);
    // console.log({selectors, attrKey});

    // console.log({method: "supply", selectorQuery, selectors})
    selectors.each((index, element) => {
      const selector = $(element);
      if (0 == selector.length) {
        // 対象が存在しない場合は何もしない
        // return;
      }
      if (selector.attr(attrKey)) {
        // 既に適用されている場合は何もしない
        return;
      }
      // まだ適用されていない場合は適用を開始する
      // console.log({selector, attrKey, module});
      this.suppling(selector, module, opts, attrKey);
    });
  }

  suppling(selector, module, opts, attrKey) {
    const supplyFunc = () => {
      selector.attr(attrKey, "supplied");
      this.supplySelector(selector, module, opts);
    };
    if (opts.delay) {
      // delayが指定されている場合は遅らせる
      // delayが指定されていない場合は、onloadでこの処理が完了することを保証するため、全体のpromise化はしない
      selector.attr(attrKey, "suppling");
      setTimeout(() => {
        supplyFunc();
      }, opts.delay);
    } else {
      supplyFunc();
    }
  }

  supplySelector(selector, module, opts) {
    // console.log({selector, module, opts});
    if (opts.checkAppliedExtends && opts.checkAppliedExtends(selector)) {
      // 拡張チェックで、適用されている事を確認した場合は何もしない
      return null;
    }
    // try {
      if (module.supplyModule) {
        // 指定されたmoduleがsupplyModuleを持っている場合
        // console.log("module class", module.supplyModule);
        return module.supplyModule(selector);
      } else {
        // console.log("module func", module);
        return module(selector);
      }
    // } catch (error) {
    //   // テーブル構成が良くない場合などにエラーになってしまうため例外を握りつぶす
    //   console.error("supply error", {error, selector, module, opts});
    // }
  }


  destroy(selectorQuery, module, opts = {
    attrKey: null,
  }) {
    const selectors = $(selectorQuery);
    const attrKey = this.createAttrKey(opts.attrKey || selectorQuery);

    selectors.each((index, element) => {
      const selector = $(element);

      if (!selector.attr(attrKey)) {
        // まだ適用されていない場合は何もしない
        return;
      }
      selector.attr(attrKey, null);
      if (module.destroyModule) {
        return module.destroyModule(selector);
      }
    });
  }

}
