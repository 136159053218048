export default class WclEvent {
  constructor() {
    this.eventsHash = {}
  }

  one(string, fn, opts = {}) {
    this.add(string, fn, {...opts, one: true});
  }

  on(string, fn, opts = {}) {
    this.add(string, fn, opts);
  }

  add(string, fn, opts = {}) {
    if (!this.eventsHash[string]) {
      this.eventsHash[string] = [];
    }
    const events = this.eventsHash[string];
    if (opts.key) {
      // opts.keyが設定されている場合
      const event = events.find(event => event.opts.key === opts.key);
      if (event) {
        // 既に登録されていた場合は登録しない
        return false;
      }
    }
    events.push({fn: fn, opts: opts});
  }

  fire(string, data = {}) {
    if (string !== "loaded") {
      // console.log("WclEvent.fire", {string, data})
    }

    const events = this.eventsHash[string];
    if (events) {
      const removes = [];
      events.forEach(event => {
        event.fn(data);
        if (event.opts.one) {
          removes.push(event);
        }
      });
      const filtered = events.filter(event => !removes.some(r => event === r));
      this.eventsHash[string] = filtered;
    }
  }

}
