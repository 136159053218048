
import { Notyf } from 'notyf';

export default class WclToaster {

  constructor(opts={}) {
    this.opts = {}
    if (window.toastr) {
      this.opts = {
        positionClass: "toast-top-right",
        closeButton: true,
        progressBar: true,
        newestOnTop: true,
        rtl: false,
        timeOut: 7500,
        extendedTimeOut: 60 * 1000,
      }
    } else if (Notyf) {
      this.opts = {
        duration: 7500,
        dismissible: true,
        icon: {
          className: 'fas fa-fw fa-bell',
          tagName: 'i',
          color: 'white',
        },
      }
    }
  }

  flash(array) {
    array.forEach((item, i) => {
      if (item[0] === "id") {
        return;
      }

      const type = item[0];
      const messages = [item[1]].flat();
      messages.forEach((message, i) => {
        this.open(message, {type: type});
      });
    });
  }

  open(message, params = {type: "success", title: null, opts: {}}) {
    if (params.type === "danger") {
      params.type = "error"
    }
    if (window.toastr) {
      // console.log("open!")
      window.toastr[params.type](
        message,
        params.title,
        {...this.opts, ...params.opts}
      );

    } else if (Notyf) {
      // Notyf を使用する場合 https://github.com/caroso1222/notyf
      // console.log({WclToaster: "open", notyf: this.notyf})
      const _message = `${(params.title ? `${params.title}\n` : "")}${message}`
      // console.log({params, _message, opts: this.opts})
      // this.notyf[params.type]({
      //   message: _message,
      //   ...this.opts,
      //   ...params.opts
      // })

      this.notyf.open({
        type: params.type,
        message: _message,
        ...this.opts,
        ...params.opts
      })

    }
  }

  close() {
    if (window.toastr) {
      window.toastr.clear();
    } else if (this.notyf) {
      // console.log({WclToaster: "close", notyf: this.notyf})
      this.notyf.dismissAll();
      this.notyf = null;
    }
  }

  recreate() {
    // console.log({WclToaster: "recreate", notyf: this.notyf})
    this.notyf = new Notyf()
  }

}
