export default class WclFileDrop {

  constructor(opts={}) {
  }

  rejectDropEventForBase(selector) {
    selector.on('drop dragover', (e) => {
      // console.log(e);
      // e.dataTransfer.setDragImage(image, xOffset, yOffset);
      e.stopPropagation();
      e.preventDefault();
    });
  }

  supplyModule(selector) {
    const fileDropper = new FileDropper(selector);
    fileDropper.setup();
  }
}

class FileDropper {

  constructor(selector) {
    this.selector = selector;
    this.element = selector[0];
    this.counter = 0;
  }

  setup() {
    const formId = $(this.element).attr("data-form-id");
    const inputId = $(this.element).attr("data-input-id");
    const dataObj = $(this.element).data();
    const singleFile = dataObj.hasOwnProperty('singleFile');
    // console.log("setup", {element: this.element, formId, inputId, dataObj, singleFile});

    this.counter = 0;

    this.element.addEventListener("dragenter", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.counter++;
      // console.log("dropZone", "dragenter", this.counter, e);
      if (1 <= this.counter) {
        this.selector.addClass("wcl-dropzone-dragenter");
      }
    }, false);

    this.element.addEventListener("dragover", (e) => {
      e.stopPropagation();
      e.preventDefault();
      // console.log("dropZone", "dragover", e);
    }, false);

    this.element.addEventListener("dragleave", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.counter--;
      // console.log("dropZone", "dragleave", this.counter, e);
      if (this.counter <= 0) {
        this.selector.removeClass("wcl-dropzone-dragenter");
      }
    }, false);

    this.element.addEventListener("drop", (e) => {
      e.stopPropagation();
      e.preventDefault();
      // console.log("dropZone", "drop", e);
      this.selector.removeClass("wcl-dropzone-dragenter");

      // ドロップされたファイルをformに渡す
      const form = $(`#${formId}`);
      const input = form.find(`#${inputId}`);

      let fileList = null;
      if (singleFile) {
        // singleFile指定時
        // 先頭ファイルのみを使用する
        let file = e.dataTransfer.items[0].getAsFile();
        let dataTransfer = new DataTransfer();
        dataTransfer.items.add(file)
        fileList = dataTransfer.files;
      } else {
        // singleFile未指定時
        fileList = e.dataTransfer.files;
      }

      // console.log("drop", {fileList});
      input[0].files = fileList;
      input.change();
    }, false);
  }
}
