console.log({wclBridge: true})

///// appstack / WCL で使用する $()拡張関数の設定 begin

// jquery系
import "jquery-mask-plugin/dist/jquery.mask.min";
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";

// moment系
import moment from "moment";
import 'moment/locale/ja'
moment.locale('ja')
window.moment = moment;


// $().DataTableの設定 begin
import dt from "datatables.net";
document.addEventListener("turbolinks:load", () => {
  dt(window, $);
});
// $().DataTableの設定 end

// select2の設定 begin
require("select2/dist/js/select2.min.js")
$.fn.select2.defaults.set("theme", "bootstrap4");
// select2の設定 end

// smartWizardの設定 begin
// import "smartwizard/dist/js/jquery.smartWizard.min.js";
// smartWizardの設定 end

// validationの設定 begin
// import "jquery-validation";
// import "jquery-validation/dist/additional-methods.js";
// validationの設定 begin

// datetimepickerの設定 begin
// import "tempusdominus-bootstrap-4";
// $.fn.datetimepicker.Constructor.Default = $.extend(
//   {},
//   $.fn.datetimepicker.Constructor.Default,
//   {
//     icons: {
//       time: "far fa-clock",
//       date: "far fa-calendar",
//       up: "fas fa-arrow-up",
//       down: "fas fa-arrow-down",
//       previous: "fas fa-chevron-left",
//       next: "fas fa-chevron-right",
//       today: "far fa-calendar-check-o",
//       clear: "fas fa-trash",
//       close: "fas fa-times"
//     }
//   }
// );
// datetimepickerの設定 end


// feather-iconsの設定 begin
import feather from "feather-icons";
window.feather = feather;
// feather-iconsの設定 end


// daterangepickerの設定 begin
import "bootstrap-daterangepicker/daterangepicker.js";
// daterangepickerの設定 end

// import '../src/bootstrap_custom.js'

require('../src/WebCommonLibrary')
///// appstack / WCL で使用する $()拡張関数の設定 end
