import dt from "datatables.net";

export default class WclDataTable {

  constructor(opts={}) {
  }

  supplyModule(selector) {
    // console.log({selector})
    const table = selector.DataTable({
      language: {
        emptyTable: "-",
      },
      scrollX: true, // 横スクロールバーを有効
      scrollY: false, // 縦スクロールバー無効
      responsive: false, // レスポンシブ無効
      searching: false, // 検索無効
      ordering: false, // ソート無効
      paging: false, // pagingは独自にやる
      info: false, // 情報は独自にやる
    });
  }
}
