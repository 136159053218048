export default class WclHtml {
  constructor() {
    this.eventsHash = {}
  }

  incrementValue(fieldId, increment, formId = null) {
    // console.log("incrementValue", {fieldId, increment, formId});
    const value = Number($(fieldId).val()) + increment;
    this.setValue(fieldId, value, formId);
  };

  setValue(fieldId, value, formId = null) {
    // console.log("setValue", {fieldId, value, formId});
    $(fieldId).val(value);

    // TODO submitするので、form.submitボタンと同じく、関連するボタンをdisable化する仕組みが欲しい
    // local: true でも使用するため、clickを使用
    const element = $(`${formId} :submit`)[0]
    // console.log("fire", {element})
    element.click();
  };

  replace(id, htmlCreateFunction, opts = {}) {
    // console.log("html.replace");
    const promise = Promise.resolve(true);
    $(document).one('ajax:complete', (event) => {
      const html = htmlCreateFunction();
      // console.log("html.replace do", {html: html});
      $(id).html(html);

      // html置換先にajax呼び元がある場合
      // completeが発生せずにonLoadPageが呼ばれないため
      // html置換後ににもう一度呼ぶ
      wcl.onLoadPage({timing: "wcl.html.replace", event});
      promise.then(() => {
        return true;
      })
    });

    return promise;
  }

  append(id, htmlCreateFunction) {
    $(id).append(htmlCreateFunction())
  }

  fire(id, opts = {}) {
    // console.log("fire", {id, opts})
    if (id) {
      // console.log($(id)[0]);
      $(id)[0].click();
    }
  }

  fires(string, opts = {}) {
    // console.log("fires", {string, opts})
    if (string) {
      // console.log($(string)[0]);
      $(string).each((index, element) => {
        element.click();
      });
    }
  }

}
