import Rails from '@rails/ujs'

export default class WclForm {
  // TODO かなりhtmlと結合してるため何とかする
  constructor() {
    this.imageInputs = new WclFormImageInputs()
    this.csvInputs = new WclFormCsvInputs()
    this.dumpCsvInputs = new WclFormDumpCsvInputs()
  }

  outerSubmit(id) {
    if (id) {
      const element = $(id)[0]
      // console.log("outerSubmit", {id, element});
      if (element) {
        // console.log("outerSubmit fire", {element});
        Rails.fire(element, 'submit');
      }
    }
  }

  changeSelectOptions(targetId, jsonDivId, hashKeys) {
    // 対象のselectのoptionを変更する
    // console.log("changeSelectOptions", {targetId, jsonDivId, hashKeys});
    const hash = $(jsonDivId).data('json');
    // console.log("changeSelectOptions", hash);

    const input = $(targetId);
    input.children("*").remove();
    const flattenHashKeys = [hashKeys].flat();
    // console.log(flattenHashKeys);
    // console.log(hash);

    let nowHash = hash;
    flattenHashKeys.forEach(key => {
      nowHash = nowHash[key] ||  [["-", ""]];
      // console.log(nowHash);
    });
    const list = nowHash;
    list.forEach(item => {
      const row = `<option value="${item[1]}">${item[0]}</option>`;
      $(row).appendTo(input);
    });
  }
}

class WclFormImageInputs {
  readFiles(fileList, onLoad) {

  }
}


class WclFormImageInputsV1 {
  onChangeImageFiles(targetId, sourceInputId, modelName, acceptContentType) {
    // 対象のdivに変更されたイメージを表示させる
    const input = $(sourceInputId);
    const div = $(targetId);
    const oldIdSuffix = moment().valueOf();

    const fileList = input.prop('files');
    // console.log("onChangeImageFiles", {fileList});
    wcl.file.readImageFileList(fileList).then(responses => {
      // 追加したファイルを読み込んでimgを作成
      let itemsHtmlString = "";
      responses.forEach(res => {
        // console.log("onChangeImageFiles", {res, this: this});
        itemsHtmlString += this.createImageItemHtml(res.result, res.file.type);
      });

      // ソート可能なdivを作成
      const containerHtmlString = this.createImageItemContainerHtml(`new-${oldIdSuffix}`, modelName, itemsHtmlString);
      const appendedItem = $(containerHtmlString).appendTo(div);

      // 今回使用したinputを移動して非表示化
      const parent = input.parent();
      input.appendTo(appendedItem);
      input.attr("id", sourceInputId + `-${oldIdSuffix}`);
      input.hide();

      // 新しいinputを追加
      const newInputHtmlString = this.createNewImageInput(modelName, acceptContentType);
      $(newInputHtmlString).appendTo(parent);
    });
  }

  onRemoveImageFiles(identifier, containerId) {
    // imageを削除する
    const div = $(containerId);
    div.children(`div`).each((index, child) => {
      if ($(child).attr('value') === identifier) {
        $(child).remove();
      }
    });
  }

  createImageItemContainerHtml(identifier, modelName, itemsHtmlString) {
    let html = `<div class="images-display-item image ui-sortable-handle" value="${identifier}">`;
    html    += `  <div class="d-flex">`;
    html    += `    ${itemsHtmlString}`;
    html    += `  </div>`;
    html    += `  <div>`;
    html    += `    <i class="close wcl-icon wcl-icon-times-circle"`;
    html    += `      onclick="wcl.form.imageInputs.onRemoveImageFiles('${identifier}', '#images-display');"></i>`;
    html    += `    <p>新規追加</p>`;
    html    += `  </div>`;
    html    += `</div>`;
    return html;
  }

  createImageItemHtml(src, type) {
    // console.log({src, type});
    let html = "";
    if (type.includes("image")) {
      html = `<img src="${src}" width="64" height="64">`;
    } else {
      html = `<img alt="pdf-file" src="/images/pdf-file.png" width="64" height="64">`;
    }
    return html;
  }

  createNewImageInput(modelName, acceptContentType) {
    let html  = `<input multiple="multiple" class="input-images-input" type="file" accept="${acceptContentType}" name="${modelName}[images][]" id="${modelName}_images"`;
    html     += `  onchange="wcl.form.imageInputs.onChangeImageFiles('#images-display', 'input#${modelName}_images', '${modelName}', '${acceptContentType}');"`;
    html     += `>`;
    return html;
  }
}

class WclFormCsvInputs {
  onChangeFiles(formId, containerId, sourceInputId, modelName) {
    // 選択されたfileからinput.hiddenを作成する
    // console.log({formId, containerId, sourceInputId, modelName});
    const form = $(formId);
    const input = $(sourceInputId);
    const div = $(containerId);
    const tbody = div.find("table tbody");
    const oldIdSuffix = moment().valueOf();

    const fileList = input.prop('files');
    // console.log("onChangeImageFiles", {fileList});
    wcl.file.readCsvFileList(fileList).then(responses => {
      // 追加したファイルを読み込んで要素を作成
      let itemsHtmlString = "";
      responses.forEach(res => {
        // console.log("onChangeFiles", {res});
        const identifier = res.file.name;

        let exists = false;
        tbody.children().each((index, child) => {
          if ($(child).attr("value") === identifier) {
            exists = true;
          }
        });

        if (exists) {
          // TODO 同じファイルが読み込まれた場合
        } else {
          const html = this.createItemHtml(identifier, res.file, res.result, formId, modelName);
          if (html !== null) {
            itemsHtmlString += html;
          }
        }
      });

      // アイテム無し行を削除
      tbody.children("#files-no-item").remove();
      // 行を追加
      const appendedItem = $(itemsHtmlString).appendTo(tbody);

      // 今回使用したinputを削除
      const parent = input.parent();
      input.remove();

      // 新しいinputを追加
      const newInputHtmlString = this.createNewInput(formId, modelName);
      $(newInputHtmlString).appendTo(parent);

      // 行変更に伴う操作
      this.onChangeRows(formId, containerId);
    });
  }

  onRemoveFiles(formId, containerId, findString, identifier) {
    const container = $(containerId);
    container.find(findString).each((index, child) => {
      if ($(child).attr('value') === identifier) {
        $(child).remove();
      }
    });
    this.onChangeRows(formId, containerId);
  }

  onChangeRows(formId, containerId) {
    const form = $(formId);
    const div = $(containerId);
    const tbody = div.find("table tbody");

    if (tbody.children().length === 0) {
      // アイテム無しの場合は「アイテム無し行」を追加
      const noItemHtml = this.createNoItemHtml();
      $(noItemHtml).appendTo(tbody);

      // submitを無効化
      form.find(":submit").each((index, child) => {
        $(child).prop("disabled", true);
      });
    } else {
      form.find(":submit").each((index, child) => {
        $(child).prop("disabled", false);
      });
    }
  }

  createItemHtml(identifier, file, value, formId, modelName) {
    const filename = file.name;
    const strings = filename.split("_", 4);
    if (strings.length !== 4) {
      // TODO 不正なファイル名
      return null;
    }
    const year = strings[0];
    const month = strings[1];
    const propertyId = strings[2];
    const propertyNameExts = strings[3];
    const extIndex = propertyNameExts.lastIndexOf(".");
    const ext = propertyNameExts.substring(extIndex + 1);
    const propertyName = propertyNameExts.substring(0, extIndex);

    let html = ``;
    html    += `<tr class="files-display-item" value="${identifier}">`;
    html    += `  <td class="small">`;
    html    += `    ${filename}`;
    html    += `    <input multiple="multiple" type="hidden" value='${filename}' name="${modelName}[filenames][]"></input>`;
    html    += `    <input multiple="multiple" type="hidden" value='${value}' name="${modelName}[files][]"></input>`;
    html    += `  </td>`;
    html    += `  <td class="text-center">${year}/${month}</td>`;
    html    += `  <td>${propertyName} (${propertyId})</td>`;
    html    += `  <td class="text-center" style="width: 18%;">`;
    html    += `    <div class="progress">`;
		html    += `      <div class="progress-bar progress-bar-striped progress-bar-animated"`;
    html    += `      role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>`;
		html    += `    </div>`;
    html    += `  </td>`;
    html    += `  <td class="text-center" style="width: 2%;">`;
    html    += `    <div>`;
    html    += `      <i class="close wcl-icon wcl-icon-times-circle"`;
    html    += `        onclick="wcl.form.csvInputs.onRemoveFiles('${formId}', '#files-display', 'tr', '${identifier}');"></i>`;
    html    += `    </div>`;
    html    += `  </td>`;
    html    += `</tr>`;
    return html;
  }

  createNoItemHtml() {
    let html = `<tr id="files-no-item">`;
    html    += `  <td colspan="5">アップロードするファイルを選んでください</td>`;
    html    += `</tr>`;
    return html;
  }

  createNewInput(formId, modelName) {
    let html  = `<input multiple="multiple" type="file" name="${modelName}[files][]" id="${modelName}_files" class='input-files-input' accept=".csv"`;
    html     += `  onchange="wcl.form.csvInputs.onChangeFiles('${formId}', '#files-display', 'input#${modelName}_files', '${modelName}');"`;
    html     += `>`;
    return html;
  }
} // csvInputs

class WclFormDumpCsvInputs { // dump用
  onChangeFiles(formId, sourceInputId, labelTextClass) {
    // 選択されたfileをlabelに反映する
    // console.log({formId, labelTextClass, sourceInputId});

    const form = $(formId);
    const input = $(sourceInputId);
    const fileList = input.prop('files');
    const labelText = form.find(labelTextClass)
    // console.log("onChangeImageFiles", {fileList});

    wcl.file.readCsvFileList(fileList).then(responses => {
      // 追加したファイルを読み込んで要素を作成
      // dropでsingleを選択しているため、1ファイルのみが入ってくる想定
      response = responses[0];
      // console.log("onChangeFiles", {response});
      const identifier = response.file.name;

      // ラベルテキストにファイル名を設定
      // console.log("setLabel", {labelText, lable_val: $(labelText).html()})
      $(labelText).html(`選択されたファイル: ${identifier}`);

      form.find(":submit").each((index, child) => {
        $(child).prop("disabled", false);
      });
    }); // readCsvFileList
  }
}

class WclFormFileDrop { // 画像アップロード
  onChangeFiles(formId, sourceInputId, labelTextClass) {
    // 選択されたfileをlabelに反映する
    // console.log({formId, labelTextClass, sourceInputId});

    const form = $(formId);
    const input = $(sourceInputId);
    const fileList = input.prop('files');
    const labelText = form.find(labelTextClass)
    // console.log("onChangeImageFiles", {fileList});

    wcl.file.readCsvFileList(fileList).then(responses => {
      // 追加したファイルを読み込んで要素を作成
      // dropでsingleを選択しているため、1ファイルのみが入ってくる想定
      response = responses[0];
      // console.log("onChangeFiles", {response});
      const identifier = response.file.name;

      // ラベルテキストにファイル名を設定
      // console.log("setLabel", {labelText, lable_val: $(labelText).html()})
      $(labelText).html(`選択されたファイル: ${identifier}`);

      form.find(":submit").each((index, child) => {
        $(child).prop("disabled", false);
      });
    }); // readCsvFileList
  }
}
