export default class WclModal {

  constructor() {
    this.modalHash = {}
  }

  getModal(id, opts = {}) {
    if (!this.modalHash[id]) {
      const _id = id.replace('#', '')
      const _element = document.getElementById(_id)
      const modal = new bootstrap.Modal(_element, opts)

      // _element.addEventListener('hidden.bs.modal', function (event) {
      //   // do something...
      //   console.log({ev: "hidden.bs.modal", event})
      // })
      // _element.addEventListener('show.bs.modal', function (event) {
      //   // do something...
      //   console.log({ev: "show.bs.modal", event})
      // })

      this.modalHash[id] = {modal: modal, opts: opts}
    }
    console.log("modal.getModal", this.modalHash[id])
    return this.modalHash[id].modal
  }

  open(id, opts = {}) {
    console.log("modal.open", {id, opts});
    // opts.show = true;
    // $(id).modal(opts);
    const modal = this.getModal(id, opts)
    modal.show()
  }

  close(id) {
    console.log("modal.close", {id});
    // $(id).modal('hide');

    this.getModal(id).hide()
  }

  recreate() {
    Object.keys(this.modalHash).forEach((id) => {
      const obj = this.modalHash[id]
      const _id = id.replace('#', '')
      const modal = new bootstrap.Modal(document.getElementById(_id), obj.opts)
      this.modalHash[id] = {modal: modal, opts: obj.opts}
    })
    // console.log({modal: "recreated", modalHash: this.modalHash})
  }
}
