import Quill from "quill"

var Delta = Quill.import('delta');
let Break = Quill.import('blots/break');
let Embed = Quill.import('blots/embed');


function lineBreakMatcher() {
  var newDelta = new Delta();
  newDelta.insert({'break': ''});
  return newDelta;
}

class SmartBreak extends Break {
  length () {
    return 1
  }
  value () {
    return '\n'
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref)
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR'

Quill.register(SmartBreak)

export default class WclTextArea {

  constructor(opts={}) {
    // this.colors = [
    //   "#000000",
    //   "#e60000",
    //   "#ff9900",
    //   "#ffff00",
    //   "#008a00",
    //   "#0066cc",
    //   "#9933ff",
    //   "#ffffff",
    //   "#facccc",
    //   "#ffebcc",
    //   "#ffffcc",
    //   "#cce8cc",
    //   "#cce0f5",
    //   "#ebd6ff",
    //   "#bbbbbb",
    //   "#f06666",
    //   "#ffc266",
    //   "#ffff66",
    //   "#66b966",
    //   "#66a3e0",
    //   "#c285ff",
    //   "#888888",
    //   "#a10000",
    //   "#b26b00",
    //   "#b2b200",
    //   "#006100",
    //   "#0047b2",
    //   "#6b24b2",
    //   "#444444",
    //   "#5c0000",
    //   "#663d00",
    //   "#666600",
    //   "#003700",
    //   "#002966",
    //   "#3d1466",
    // ]

    this.toolbarOptions = [
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'align': [] }],
      ['link',],
      // ['image', 'video'],
      ['clean'],                                        // remove formatting button
      ['showHtml'],
    ]
  }


  supplyModule(selector) {
    // console.log("WclTextArea: supply", selector);
    const id = `wcl-text-area-${wcl.utils.uniqueId()}`;
    selector.attr('id', id);
    const editor = new Quill(`#${id}`, {
      modules: {
        toolbar: this.toolbarOptions,
        clipboard: {
          matchers: [
            ['BR', lineBreakMatcher]
          ]
        },
        keyboard: {
          bindings: {
            linebreak: {
              key: 13,
              shiftKey: true,
              handler: function (range) {
                let currentLeaf = this.quill.getLeaf(range.index)[0]
                let nextLeaf = this.quill.getLeaf(range.index + 1)[0]

                this.quill.insertEmbed(range.index, 'break', true, 'user');

                // Insert a second break if:
                // At the end of the editor, OR next leaf has a different parent (<p>)
                if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
                  this.quill.insertEmbed(range.index, 'break', true, 'user');
                }

                // Now that we've inserted a line break, move the cursor forward
                this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
              }
            }
          }
        }
      },
      placeholder: "",
      theme: "snow"
    })

    var length = editor.getLength()
    var text = editor.getText(length - 2, 2)

    // Remove extraneous new lines
    if (text === '\n\n') {
      editor.deleteText(editor.getLength() - 2, 2)
    }

    const parentForm = selector.closest('form')
    const hiddenField = parentForm.find('input.wcl-text-area-hidden-field');
    const htmlTextArea = $('<textarea>', {class: 'wcl-text-area-html-preview'});
    const container = $('<div>', {class: 'wcl-text-area-container'});
    selector.append(container);
    container.append(htmlTextArea)
    // const htmlEditor = editor.addContainer('ql-custom');
    // htmlTextArea.app
    // htmlEditor.appendChild(htmlTextArea[0])

    // console.log({parentForm, hiddenField})
    editor.on('text-change', (delta, oldDelta, source) => {
      // console.log({delta, oldDelta, source})
      // console.log({html: editor.root.innerHTML})
      const html = editor.root.innerHTML;

      // console.log({hiddenField: hiddenField})
      if (0 < hiddenField.length) {
        // 親フォームがあれば、値を設定する
        hiddenField[0].value = html;
      }

      // console.log({htmlTextArea: htmlTextArea})
      if (0 < htmlTextArea.length) {
        htmlTextArea[0].value = html;
      }
    })

    // view_htmlボタン押下時のtoggle
    const customButton = parentForm.find('button.ql-showHtml');
    // console.log({customButton});
    customButton.on('click', () => {
      const nowDisplay = htmlTextArea.css('display');
      // console.log({nowDisplay});
      if ('none' === nowDisplay) {
        const html = editor.root.innerHTML;
        htmlTextArea[0].value = html;
        htmlTextArea.css('display', 'block');
      } else {
        const html = htmlTextArea[0].value
        editor.pasteHTML(html)
        htmlTextArea.css('display', 'none');
      }
    });
  }
}
