export default class WclProgress {
  start() {
    return new Promise((resolve, reject) => {
      if (!Turbolinks.supported) {
        return reject();
      }
      Turbolinks.controller.adapter.progressBar.setValue(0);
      Turbolinks.controller.adapter.progressBar.show();
      return resolve(true);
    });
  }
  stop() {
    return new Promise((resolve, reject) => {
      if (!Turbolinks.supported) {
        return reject();
      }
      Turbolinks.controller.adapter.progressBar.hide();
      Turbolinks.controller.adapter.progressBar.setValue(100);
      return resolve(true);
    });
  }
}
