export default class WclSelect2Wrapper {
  constructor(selector) {
    this.selector = selector;
    this.language = {
      noResults: function() { return "検索結果 0 件"; },
      removeAllItems: function() { return 'すべてのアイテムを削除'; }
    };
    this.queryBase = {};
    this.wasSelectedInitialValue = false;
  }

  getAttrs() {
    this.attrs = {
      hash: this.selector.data('hash'), // jsonで取得するために data でアクセス
      required: this.selector.attr('required'), // 通常のattr
      selectorIdSuffix: this.selector.attr('data-selector-id-suffix'),
      groupId: this.selector.attr('data-group-id'),
      columnId: this.selector.attr('data-column-id'),
      initialSelected: this.selector.attr('data-initial-selected'),
      initialSelecteds: this.selector.attr('data-initial-selecteds') ? JSON.parse(this.selector.attr('data-initial-selecteds')) : [],
      url: this.selector.attr('data-url'),
      extendSpanClass: this.selector.attr('data-extend-span-class'),
      placeholder: this.selector.attr('data-placeholder'),
      relationalParent: this.selector.attr('data-relational-parent'),
      relationalChild: this.selector.attr('data-relational-child'),
      readonly: this.selector.attr('data-readonly') == 'true',
      requiredQuery: this.selector.attr('data-required-query') ? JSON.parse(this.selector.attr('data-required-query')) : {},
    };

    this.parentStatus = {};
    this.attrs.relationalParents = [];
    if (this.attrs.relationalParent) {
      const parents = this.attrs.relationalParent.split(" ");
      this.attrs.relationalParents = parents.filter(Boolean); // 空要素を削除して設定
      this.attrs.relationalParents.forEach(parent => this.parentStatus[`${this.attrs.groupId}-${parent}`] = false);
    }

    this.attrs.relationalChildren = [];
    if (this.attrs.relationalChild) {
      const children = this.attrs.relationalChild.split(" ");
      this.attrs.relationalChildren = children.filter(Boolean); // 空要素を削除して設定
    }
    // console.log("getAttrs", this.attrs, this.parentStatus);
  }


  init() {
    // console.log("init", {selector: this.selector, this: this});
    // data-attrから値を取得
    this.getAttrs();
    // attrからインスタンス変数を設定
    this.attrsToInstanceData();
    // 設定
    this.setSelect2();
    // イベントhandlerの設定
    this.selector.on("change", (e, arg) => {
      // 値変更時に子に通知する
      // console.log("on change", {e, arg});
      this.triggerRelationParentChange(e);
    });
    this.selector.on("wcl:relation-parent-change", (e, params) => {
      // 親からの変更通知
      // console.log("wcl:relation-parent-change", {e, params});
      this.onRelationParentChange(e, params);
    });
  }

  triggerRelationParentChange(e, initialized = false) {
    // 自身の値が更新・初期化された場合に、子に通知する

    // console.log("triggerRelationParentChange", {this: this, e, initialized, relationalChildren: this.attrs.relationalChildren})
    if (!this.attrs.relationalChildren) {
      return false;
    }
    const value = this.selector.val();

    this.attrs.relationalChildren.forEach(child => {
      const childSelectQuery = `select[data-selector-id-suffix="${child}"][data-group-id="${this.attrs.groupId}"]`;
      const childSelector = $(childSelectQuery);
      const childAttr = `data-${this.attrs.columnId}`;
      // console.log("triggerRelationParentChange child", {e, value, childSelectQuery, childSelector, initialized});
      childSelector.attr(childAttr, value);
      childSelector.trigger("wcl:relation-parent-change", {
        groupId: this.attrs.groupId,
        columnId: this.attrs.columnId,
        value: value,
        selectorIdSuffix: this.attrs.selectorIdSuffix,
        initialized: initialized,
      });
    });
  }


  triggerRelationParentInitialized() {
    // 自身の値が初期化された場合に、子に通知する
    setTimeout(() => {
      // childが生成されるのを待ってからトリガー
      this.triggerRelationParentChange({}, true);
    }, 64);
  }

  onRelationParentChange(e, params) {
    // 親の値が更新・初期化された場合に、自身の値を変更する
    // console.log("onRelationParentChange", params);
    const {groupId, columnId, value, selectorIdSuffix, initialized} = params

    const parentStatusKey = `${groupId}-${selectorIdSuffix}`;
    this.parentStatus[parentStatusKey] = this.parentStatus[parentStatusKey] || initialized;
    // console.log("onRelationParentChange", {parentStatusKey, parentStatus: this.parentStatus});

    // 親の値が変更になった場合
    const prevValue = this.queryBase[columnId];
    this.queryBase[columnId] = value;

    if (this.wasSelectedInitialValue !== "complete") {
      // 自身の初期化が完了していない場合は
      // 親が初期化された可能性があるため、自身も初期値の取得を試みる
      // console.log("onRelationParentChange", "init!")
      this.initSelection();

    } else if (prevValue !== value) {
      // 親の値が変更になった場合は
      // 自身の値をクリア
      // console.log("onRelationParentChange", "clear!")
      this.selector.val(null).trigger("change");
    }
  }

  setPlaceholder(progress=false) {
    if (progress) {
      this.placeholder = "取得中...";
    } else {
      this.placeholder = this.attrs.placeholder ? this.attrs.placeholder // 指定されている場合
        : this.attrs.required ? "選択してください" // ブランクが許されない
        : "全て" // ブランクが許される
      ;
    }
  }

  setSelect2() {
    const params = {
      width: 'resolve',
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      language: this.language,
      allowClear: true,
      placeholder: this.placeholder,
    };
    if (this.data) {
      params.data = this.data;
    } else if (this.ajax) {
      params.ajax = this.ajax;
    }

    // 適用
    // console.log("setSelect2", {params});
    this.selector.select2(params);
    this.initSelection();

    // form-h3 などの 拡張クラスが指定された場合の処理
    if (this.attrs.extendSpanClass) {
      const selectorSpanSelection = this.selector.next("span").find("span.select2-selection");
      selectorSpanSelection.addClass(this.attrs.extendSpanClass);
    }

    // windowがリサイズされたときにselect2もリサイズする処理
    $(window).on('resize', () => {
      const parentFormGroup = this.selector.parent('.form-group')
      // console.log({on: "resize", selector: this.selector, parentFormGroup: parentFormGroup})
      parentFormGroup.find('.select2-container').css('width', parentFormGroup.width());
    });
  }

  initSelection() {
    // 初期値設定
    // モジュールが初期化されたタイミングと、親がの値が変更されたタイミングで呼ばれる
    // console.log("initSelection", {t: this});

    if (this.wasSelectedInitialValue === "complete") {
      // 初期値設定が完了している場合
      // console.log("initSelection", "wasSelectedInitialValue");
      return false;
    }

    if (!this.attrs.initialSelected && this.attrs.initialSelecteds <= 0) {
      // console.log("initSelection not selected", {attrs: this.attrs});
      // 初期値が必要無い場合
      this.selector.val(null).trigger("change");
      this.wasSelectedInitialValue = "complete";
      this.triggerRelationParentInitialized();
      return false;
    }

    if (this.data) {
      // dataの場合
      // console.log("initSelection data selected", {attrs: this.attrs});
      this.selector.val(this.attrs.initialSelected).trigger("change");
      this.wasSelectedInitialValue = "complete";
      this.triggerRelationParentInitialized();
      return true;

    } else if (this.ajax) {
      // ajaxの場合
      // console.log("initSelection: ajax!")

      if (this.wasSelectedInitialValue !== "waiting") {
        this.wasSelectedInitialValue = "waiting";
        // 一旦ダミーを設定

        // console.log({initialSelected: this.attrs.initialSelected, initialSelecteds: this.attrs.initialSelecteds});
        (0 < this.attrs.initialSelecteds.length ? this.attrs.initialSelecteds : [this.attrs.initialSelected]).forEach((initialSelected, i) => {
          // console.log({initialSelected: initialSelected})
          const dummyOption = new Option("データ取得中...", initialSelected, true, true);
          this.selector.append(dummyOption).trigger('change');
          this.selector.trigger({type: 'select2:select', params: {data: [{text: "", id: initialSelected, progress: true}]}});
        });

      }

      const allParentsInitialized = Object.keys(this.parentStatus).every(key => !!this.parentStatus[key]);
      if (this.attrs.relationalParent && !allParentsInitialized) {
        // 親が存在して、かつ、全ての親の初期化が完了していない場合
        // 親の初期化後にqueryを作成するため、自身の初期化を待つ
        // console.log("wait...", {parentStatus: this.parentStatus, relationalParent: this.attrs.relationalParent, initializedParent: this.initializedParent, allParentsInitialized});
        return false;
      }
      // console.log("go!", {parentStatus: this.parentStatus, relationalParent: this.attrs.relationalParent, initializedParent: this.initializedParent, allParentsInitialized});

      if (this.wasSelectedInitialValue === "waiting") {
        // 取得開始
        this.wasSelectedInitialValue = "complete";
        const query = {search: {...this.attrs.requiredQuery}};
        if (0 < this.attrs.initialSelecteds.length) {
          query.search[this.attrs.columnId] = this.attrs.initialSelecteds;
        } else {
          query.search[this.attrs.columnId] = this.attrs.initialSelected;
        }
        // console.log("get ajax init", {url: this.attrs.url, query});
        $.ajax({
          type: 'GET',
          url: this.attrs.url,
          data: query,
        }).then((res) => {
          // console.log("initial ajax", {res, rid: res.results.id});
          if (res.results && 1 <= res.results.length) {
            (0 < this.attrs.initialSelecteds.length ? this.attrs.initialSelecteds : [this.attrs.initialSelected]).forEach((initialSelected, i) => {
              // console.log({initialSelected: initialSelected})
              this.selector.find("option[value='" + initialSelected + "']").remove();
              const result = res.results.find(result => result.id == initialSelected)
              const option = new Option(result.text, result.id, true, true);
              this.selector.append(option).trigger('change');
              this.selector.trigger({type: 'select2:select', params: {data: result}});
            });

            // const result = res.results[0];
            // this.selector.find("option[value='" + result.id + "']").remove();
            // const option = new Option(result.text, result.id, true, true);
            // this.selector.append(option).trigger('change');
            // this.selector.trigger({type: 'select2:select', params: {data: result}});
            this.triggerRelationParentInitialized();
          }

        });

      }
    }
  }

  templateResult(item) {
    // console.log("templateResult", item);
    if (item.children) {
      return $(`<div><span class="badge badge-info">${item.text}</span></div>`);
    } else {
      return $(`<div class="small">${item.text}</div>`);
    }
  }

  templateSelection(item) {
    // console.log("templateSelection", item);
    if (item.text === "データ取得中...") {
      return $(`
        <span class="mr-2">
          <div class="spinner-grow spinner-grow-sm text-dark mr-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          ${item.text}
        </span>
      `);
    } else {
      return $(`<span class="mr-2">${item.text}</span>`);
    }
  }

  attrsToInstanceData() {
    // select2に渡すデータを作成する
    // console.log("attrsToInstanceData", {t: this});
    this.placeholder = null;
    this.data = null;
    this.ajax = null;
    this.dataPromise = null;

    if (this.attrs.hash) {
      // dataが指定されている場合
      const resultsObject = this.hashToResultsObject(this.attrs.hash);
      this.data = resultsObject.data;
      this.placeholder = resultsObject.placeholder;
      // console.log("attrsToInstanceData", {t: this});

    } else if (this.attrs.url) {
      // 検索もリストの取得も ajax でやる場合には、select2 の ajax を利用する
      this.setPlaceholder();
      this.ajax = {
        url: this.attrs.url,
        data: (params) => {
          const query = {
            search: {
              seed: Math.random(),
              page: params.page || 1,
              text: params.term,
              ...this.attrs.requiredQuery,
              ...this.queryBase,
            }
          };
          // console.log("ajax data", {params, query, attrs: this.attrs, requiredQuery: this.attrs.requiredQuery, queryBase: this.queryBase});
          return query;
        },
        processResults: function (data, params) {
          // console.log("processResults", {data, params});
            params.page = params.page || 1;
            return {
                results: data.results,
                pagination: {
                    more: (params.page * 10) < data.count_filtered
                }
            };
        },
      };
    }
  }

  hashToResultsObject(hash) {
    let placeholder = null;
    const data = hash.map(item => {
      const id = item[1];
      const value = item[0];
      const extension = item[2];
      if ((!id && id !==0) || id === "") {
        // [全て] 項目がある場合の処理
        placeholder = value;
        return null;
      }
      const idString = String(id);
      // const extension = idString ? ` (${idString})` : "";
      const text = extension ? `${value}${extension}` : `${value}`;
      return {
        text: text,
        id: idString,
        extension: extension,
      }
    }).filter(v => v);
    return {data, placeholder};
  }

  matcher(params, data) {
  }
}
