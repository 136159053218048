$(() => {
  $(document).on('ajax:before', (event) => {
    // console.log("ajax:before", {event});
    wcl.progress.start();
  });

  $(document).on('ajax:beforeSend', (event) => {
    // console.log("ajax:beforeSend", {event});
  });

  $(document).on('ajax:send', (event) => {
    // console.log("ajax:send", {event});
  });

  $(document).on('ajax:stopped', (event) => {
    // console.log("ajax:stopped", {event});
  });

  $(document).on('ajax:success', (event) => {
    const target = event.target;
    const detail = event.detail;
    // console.log("ajax:success", {event, target, detail});
    // showMessage("success", target.toString());

    const data = detail[0];
    const status = detail[1];
    const xhr = detail[2];

    // ブラウザバックやリロードに備えてajax成功時にurlを置き換える
    const url = new URL(xhr.responseURL);
    const pathname = location.pathname;
    const search = url.search;
    const urlString = `${pathname}${search}`;
    // console.log({xhrResponseURL: xhr.responseURL, url, pathname, search});
    if (search.includes("_transition=false")) {
      // TODO urlを置き換えない。現状は quieryStringで判定。
    } else {
      window.history.replaceState({turbolinks: true, url: urlString}, '', urlString);
    }

    if (window.wcl && window.wcl.event) {
      window.wcl.event.fire("wcl-ajax::on_success", {target})
    }
  });

  $(document).on('ajax:error', (event) => {
    console.error("ajax:error", {event});
    showMessage("danger", event.originalEvent.detail[1]);
  });

  $(document).on('ajax:complete', (event) => {
    // console.log("ajax:complete", {event});
    wcl.onLoadPage({timing: 'turbolinks:load', event});

    const target = event.target;
    if (window.wcl && window.wcl.event) {
      window.wcl.event.fire("wcl-ajax::on_complete", {target})
    }
  });

  const showMessage = (messageType, message) => {
    if (window.wcl && window.wcl.toaster) {
      window.wcl.toaster.open(message, {type: messageType, title: "network"})

    } else {
      let html = `<div class="alert alert-${messageType} alert-dismissible" role="alert">`;
      html    += `  <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">`;
      html    += `    <span aria-hidden="true">×</span>`;
      html    += `  </button>`;
      html    += `  <div class="alert-message">`;
      html    += `    ${message}`;
      html    += `  </div>`;
      html    += `</div>`;
      $("#alert-container").html(html);
    }
  };
});
