import WclProgress from "./libs/WclProgress"
import WclEvent from "./libs/WclEvent"
import WclSupplyer from "./libs/WclSupplyer"
import WclDatePicker from "./libs/WclDatePicker"
import WclDateRangePicker from "./libs/WclDateRangePicker"
import WclSelect from "./libs/WclSelect"
import WclSidebar from "./libs/WclSidebar"
import WclDataTable from "./libs/WclDataTable"
import WclFileDrop from "./libs/WclFileDrop"
import WclTabList from "./libs/WclTabList"
import WclHtml from "./libs/WclHtml"
import WclModal from "./libs/WclModal"
import WclUtils from "./libs/WclUtils"
import WclFile from "./libs/WclFile"
import WclForm from "./libs/WclForm"
import WclGlobalValue from "./libs/WclGlobalValue"
import WclTextArea from "./libs/WclTextArea"
import WclToaster from "./libs/WclToaster"
import WclPicker from "./libs/WclPicker"
import WclResizer from "./libs/WclResizer"


export default class Wcl {
  constructor() {
    this.progress = new WclProgress()
    this.event = new WclEvent()
    this.supplyer = new WclSupplyer()
    this.datePicker = new WclDatePicker()
    this.dateRangePicker = new WclDateRangePicker()
    this.select = new WclSelect()
    this.sidebar = new WclSidebar()
    this.dataTable = new WclDataTable()
    this.fileDrop = new WclFileDrop()
    this.tabList = new WclTabList()
    this.html = new WclHtml()
    this.modal = new WclModal()
    this.utils = new WclUtils()
    this.file = new WclFile()
    this.form = new WclForm()
    this.globalValue = new WclGlobalValue()
    this.textArea = new WclTextArea()
    this.toaster = new WclToaster()
    this.picker = new WclPicker()
    this.resizer = new WclResizer()
  }

  onLoadPage(opts) {
    // 以下のタイミングで呼ばれる
    // - turbolinks:load
    // - ajax:complete

    // console.log("onLoadPage", opts);

    // progressを終了させる
    this.progress.stop()

    // 各moduleを適用
    this.supplies(opts)

    try {
      // icon再表示
      feather.replace();
    } catch (e) {
      // TODO 存在しない場合はエラーになってしまうため、とりあえず最後に配置
      console.error("error feather", e);
    }

    this.event.fire("loaded", opts);
  }

  onBeforeCache (opts) {
    // 以下のタイミングで呼ばれる
    // - turbolinks:before-cache
    // browserバック時に再適用が必要なモジュールに関する記述を行う
    // console.log("onBeforeCache", opts);
    this.supplyer.destroy('.wcl-select', this.select);
  }

  supplies(opts) {

    // DateTimePicker系を適用
    this.datePicker.supplyModule();
    this.dateRangePicker.supplyModule();

    // autocomplete系を適用
    this.supplyer.supply('.wcl-select', this.select);

    // textArea系を適用
    this.supplyer.supply('.wcl-text-area', this.textArea, {attrKey: "text-area"});

    // sidebar系を適用
    this.supplyer.supply(".wcl-sidebar-toggle", this.sidebar);

    // resizer系を適用
    this.supplyer.supply(".wcl-resizable", this.resizer);

    // file-dropable系を適用
    this.supplyer.supply('#wrapper', this.fileDrop.rejectDropEventForBase, {attrKey: "dropzone-reject"});
    this.supplyer.supply('.wcl-dropzone', this.fileDrop.supplyModule);

    // dataTableを適用
    // if (opts.aa) {
    //   this.supplyer.supply('table.dataTable', this.dataTable.supplyModule);
    // }
    this.supplyer.supply('table.dataTable', this.dataTable.supplyModule);

    // TODO dragulaを使用しているっぽい
    // // sortableを付与
    // $('.image-sortable').sortable({
    //   containment: 'parent',
    //   revert: 20,
    //   tolerance: "pointer",
    //   axis: 'x',
    //   items: '.image',
    // });
    //
    // $('.list-sortable').sortable({
    //   containment: 'parent',
    //   revert: 20,
    //   tolerance: "pointer",
    //   axis: 'y',
    //   items: '.list-item',
    //   change: (ev, ui) => {
    //     // console.log("list-sortable change", {ev, ui});
    //   },
    //   update: (ev, ui) => {
    //     // console.log("list-sortable update", {ev, ui});
    //     const container = $(ev.target);
    //     const items = container.find(".list-item");
    //     const item = ui.item;
    //     this.event.fire("list-sortable-update", {ev, ui, container, items, item});
    //   },
    // });

    // data-maskを適用
    $.applyDataMask();

    // tab切り替え時
    this.supplyer.supply('a[data-toggle="list"]', this.tabList.supplyModule);

    // pickerを適用
    this.supplyer.supply('.wcl-picker-container', this.picker);

    // TODO その他、bootstrap基本機能
    // $('[data-toggle="popover"]').popover();
    // $('[data-toggle="dropdown"]').dropdown();
    // $('[data-toggle="tooltip"]').tooltip();


    // bootstrap基本機能の対象を再作成
    this.toaster.recreate();
    this.modal.recreate();

  }
}
