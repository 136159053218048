export default class WclResizer {

  constructor(opts={}) {
    this.resizableDivs = []
  }

  supplyModule(selector) {
    const resizableDiv = new ResizableDiv()
    resizableDiv.makeResizableDiv(selector)
    this.resizableDivs.push(resizableDiv)
  }
}

class ResizableDiv {
  constructor(opts={}) {
    this.logcount = 0

    this.key = "_"

    this.aspectRatioFixed = true // TODO 縦横比固定にしない場合
    this.aspectRatio = 1

    this.minimum_size = 20;

    this.limitToParentSize = true // TODO 親サイズに制限されないようにする場合
    this.resizableAreaSize = {
      width: 0,
      height: 0,
    }
  }


  onChangeRect() {
    const elementRectInfo = {
      key: this.key,
      ...this.getElementRectInfo(this.element)
    }
    wcl.event.fire("Transformable::onChangeRect", elementRectInfo)
  }

  getElementRectInfo(_element) {
    return {
      width: parseFloat(getComputedStyle(_element, null).getPropertyValue('width').replace('px', '')),
      height: parseFloat(getComputedStyle(_element, null).getPropertyValue('height').replace('px', '')),
      left: _element.offsetLeft,
      top: _element.offsetTop,
    }
  }

  getAttrs(_selector) {
    const _aspectRatioFree = _selector.attr('data-aspect-ratio-free') == 'true'
    if (_aspectRatioFree) {
      this.aspectRatioFixed = false
    }
    const _key = _selector.attr('data-key')
    if (_key) {
      this.key = _key
    }
  }

  makeResizableDiv(selector) {

    this.getAttrs(selector)

    // console.log(selector)
    this.element = selector[0]
    const resizers = $(selector).find('.resizer')
    // console.log({element: this.element, resizers})

    if (this.aspectRatioFixed) {
      // 縦横比を固定
      const _elementRectInfo = this.getElementRectInfo(this.element)
      this.aspectRatio = _elementRectInfo.height / _elementRectInfo.width
      // console.log({aspr: this.aspectRatio})
    }

    if (this.limitToParentSize) {
      // 親サイズで制限
      const _resizableLimitElement = $(selector).parent('div')[0]
      const _parentRectInfo = this.getElementRectInfo(_resizableLimitElement)
      this.resizableAreaSize.width = _parentRectInfo.width
      this.resizableAreaSize.height = _parentRectInfo.height
    }

    // 本体のドラッグ処理
    this.element.addEventListener('mousedown', (e) => {
      e.preventDefault()

      const elementRectInfo = this.getElementRectInfo(this.element);
      const initialMousePosition = this.getMousePosition(e);

      // this.updateLocalValsOnClick(e);

      const moveFunction = (e) => { this.move(e, elementRectInfo, initialMousePosition) }
      const moveEndFunction = (e) => {
        window.removeEventListener('mousemove', moveFunction)
        window.removeEventListener('mouseup', moveEndFunction)
        this.onChangeRect() // form値へのコールバック
      }
      window.addEventListener('mousemove', moveFunction)
      window.addEventListener('mouseup', moveEndFunction)

    })

    for (let i = 0;i < resizers.length; i++) {
      // 4辺のドラッグ処理
      const currentResizer = resizers[i];
      currentResizer.addEventListener('mousedown', (e) => {
        e.preventDefault()
        e.stopPropagation(); // これが無いと本体のドラッグ処理も一緒に発動してしまう

        const elementRectInfo = this.getElementRectInfo(this.element);
        const initialMousePosition = this.getMousePosition(e);
        // this.updateLocalValsOnClick(e);

        const resizeFunction = (e) => { this.resize(e, elementRectInfo, initialMousePosition, currentResizer) }
        const resizeEndFunction = (e) => {
          window.removeEventListener('mousemove', resizeFunction)
          window.removeEventListener('mouseup', resizeEndFunction)
          this.onChangeRect() // form値へのコールバック
        }
        window.addEventListener('mousemove', resizeFunction)
        window.addEventListener('mouseup', resizeEndFunction)
      })
    }
  }

  getMousePosition(e) {
    return {
      x: e.pageX,
      y: e.pageY,
    }
  }

  isValidRect(elementRectInfo) {
    return (this.minimum_size < elementRectInfo.width && this.isInAreaX(elementRectInfo.left, elementRectInfo.width))
      && (this.minimum_size < elementRectInfo.height && this.isInAreaY(elementRectInfo.top, elementRectInfo.height))
  }

  isInAreaX(left, width) {
    return (!this.limitToParentSize || (0 <= left) && (left + width <= this.resizableAreaSize.width))
  }

  isInAreaY(top, height) {
    return (!this.limitToParentSize || (0 <= top) && (top + height <= this.resizableAreaSize.height))
  }

  move(e, initialElementRectInfo, initialMousePosition) {
    this.logcount ++
    if (this.logcount % 100 == 0) {
      this.logcount = 0
    }

    const mousePosition = this.getMousePosition(e)
    const amountOfChangeX = mousePosition.x - initialMousePosition.x
    const amountOfChangeY = mousePosition.y - initialMousePosition.y

    const transformedRectInfo = {...initialElementRectInfo}

    transformedRectInfo.left = initialElementRectInfo.left + amountOfChangeX
    transformedRectInfo.top = initialElementRectInfo.top + amountOfChangeY

    if (this.limitToParentSize) {
      if (transformedRectInfo.left < 0) {
        transformedRectInfo.left = 0
      } else if(this.resizableAreaSize.width < transformedRectInfo.left + initialElementRectInfo.width) {
        transformedRectInfo.left = this.resizableAreaSize.width - initialElementRectInfo.width
      }

      if (transformedRectInfo.top < 0) {
        transformedRectInfo.top = 0
      } else if(this.resizableAreaSize.height < transformedRectInfo.top + initialElementRectInfo.height) {
        transformedRectInfo.top = this.resizableAreaSize.height - initialElementRectInfo.height
      }

    }

    if (this.isValidRect(transformedRectInfo)) {
      this.element.style.left = transformedRectInfo.left + 'px'
      this.element.style.top = transformedRectInfo.top + 'px'
    }

  }

  resize(e, initialElementRectInfo, initialMousePosition, currentResizer) {
    this.logcount ++
    if (this.logcount % 100 == 0) {
      this.logcount = 0
    }

    const mousePosition = this.getMousePosition(e)
    const amountOfChangeX = mousePosition.x - initialMousePosition.x
    const amountOfChangeY = mousePosition.y - initialMousePosition.y

    const transformedRectInfo = {...initialElementRectInfo}

    if (currentResizer.classList.contains('bottom-right')) {
      transformedRectInfo.width = initialElementRectInfo.width + amountOfChangeX
      transformedRectInfo.height = initialElementRectInfo.height + amountOfChangeY

      const ajustedByAspect = this.limitedSizeByAspectRatio(amountOfChangeX, amountOfChangeY, transformedRectInfo)
      transformedRectInfo.width = ajustedByAspect.width
      transformedRectInfo.height = ajustedByAspect.height

      const ajustedByArea = this.limitedSizeByArea(transformedRectInfo)
      transformedRectInfo.width = ajustedByArea.width
      transformedRectInfo.height = ajustedByArea.height

    } else if (currentResizer.classList.contains('bottom-left')) {
      transformedRectInfo.width = initialElementRectInfo.width - amountOfChangeX
      transformedRectInfo.height = initialElementRectInfo.height + amountOfChangeY
      transformedRectInfo.left = initialElementRectInfo.left + amountOfChangeX
      // if (this.logcount == 0) console.log(transformedRectInfo)


      const ajustedByAspect = this.limitedSizeByAspectRatio(amountOfChangeX, amountOfChangeY, transformedRectInfo)
      transformedRectInfo.width = ajustedByAspect.width
      transformedRectInfo.height = ajustedByAspect.height
      transformedRectInfo.left = ajustedByAspect.left

      const ajustedByArea = this.limitedSizeByArea(transformedRectInfo)
      transformedRectInfo.width = ajustedByArea.width
      transformedRectInfo.height = ajustedByArea.height
      transformedRectInfo.left = ajustedByArea.left

    } else if (currentResizer.classList.contains('top-right')) {
      transformedRectInfo.width = initialElementRectInfo.width + amountOfChangeX
      transformedRectInfo.height = initialElementRectInfo.height - amountOfChangeY
      transformedRectInfo.top = initialElementRectInfo.top + amountOfChangeY

      const ajustedByAspect = this.limitedSizeByAspectRatio(amountOfChangeX, amountOfChangeY, transformedRectInfo)
      // if (this.logcount == 0) console.log(transformedRectInfo)
      transformedRectInfo.width = ajustedByAspect.width
      transformedRectInfo.height = ajustedByAspect.height
      transformedRectInfo.top = ajustedByAspect.top

      const ajustedByArea = this.limitedSizeByArea(transformedRectInfo)
      transformedRectInfo.width = ajustedByArea.width
      transformedRectInfo.height = ajustedByArea.height
      transformedRectInfo.top = ajustedByArea.top

    } else {
      transformedRectInfo.width = initialElementRectInfo.width - amountOfChangeX
      transformedRectInfo.height = initialElementRectInfo.height - amountOfChangeY
      transformedRectInfo.left = initialElementRectInfo.left + amountOfChangeX
      transformedRectInfo.top = initialElementRectInfo.top + amountOfChangeY

      const ajustedByAspect = this.limitedSizeByAspectRatio(amountOfChangeX, amountOfChangeY, transformedRectInfo)
      transformedRectInfo.width = ajustedByAspect.width
      transformedRectInfo.height = ajustedByAspect.height
      transformedRectInfo.left = ajustedByAspect.left
      transformedRectInfo.top = ajustedByAspect.top

      const ajustedByArea = this.limitedSizeByArea(transformedRectInfo)
      transformedRectInfo.width = ajustedByArea.width
      transformedRectInfo.height = ajustedByArea.height
      transformedRectInfo.left = ajustedByArea.left
      transformedRectInfo.top = ajustedByArea.top
    }

    if (this.isValidRect(transformedRectInfo)) {
      this.element.style.width = transformedRectInfo.width + 'px'
      this.element.style.height = transformedRectInfo.height + 'px'
      this.element.style.left = transformedRectInfo.left + 'px'
      this.element.style.top = transformedRectInfo.top + 'px'
    }
  }


  limitedSizeByArea (rectInfo) {
    // エリアに入るようにrectInfoを調整する
    // 調整後の情報を返す

    const adjusted = {...rectInfo}

    if (this.limitToParentSize) {
      // エリア制限
      if (rectInfo.left < 0) {
        adjusted.left = 0
        adjusted.width = rectInfo.width + (rectInfo.left - adjusted.left)
      } else if(this.resizableAreaSize.width < rectInfo.left + rectInfo.width) {
        adjusted.width = this.resizableAreaSize.width - rectInfo.left
      }
    }

    if (rectInfo.width < this.minimum_size) {
      // 最小値制限
      adjusted.width = this.minimum_size
      adjusted.left = rectInfo.left + (rectInfo.width - adjusted.width)
    }

    if (this.limitToParentSize) {
      // エリア制限
      if (rectInfo.top < 0) {
        adjusted.top = 0
        adjusted.height = rectInfo.height + (rectInfo.top - adjusted.top)
      } else if(this.resizableAreaSize.height < rectInfo.top + rectInfo.height) {
        adjusted.height = this.resizableAreaSize.height - rectInfo.top
      }
    }

    if (rectInfo.height < this.minimum_size) {
      // 最小値制限
      adjusted.height = this.minimum_size
      adjusted.top = rectInfo.top + (rectInfo.height - adjusted.height)
    }


    if ((adjusted.width != rectInfo.width)
      || (adjusted.height != rectInfo.height)
    ) {
      // 制限値による変更があった場合
      if (this.aspectRatioFixed) {
        // アスペクト制限がある場合は、最大かつはみ出ない様にする
        if (1 < this.aspectRatioFixed) {
          adjusted.width = Math.round(adjusted.height / this.aspectRatio)
          adjusted.left = rectInfo.left + (rectInfo.width - adjusted.width)
        } else {
          adjusted.height = Math.round(adjusted.width * this.aspectRatio)
          adjusted.top = rectInfo.top + (rectInfo.height - adjusted.height)
        }
      }
    }
    return adjusted
  }

  limitedSizeByAspectRatio (amountOfChangeX, amountOfChangeY, rectInfo) {
    // アスペクト比に併せてrectInfoを調整する
    // 調整後の情報を返す
    const adjusted = {...rectInfo}
    if (this.aspectRatioFixed) {
      if (Math.abs(amountOfChangeX) < Math.abs(amountOfChangeY)) {
        adjusted.width = Math.round(rectInfo.height / this.aspectRatio)
        adjusted.left = rectInfo.left + (rectInfo.width - adjusted.width)
      } else {
        adjusted.height = Math.round(rectInfo.width * this.aspectRatio)
        adjusted.top = rectInfo.top + (rectInfo.height - adjusted.height)
      }
    }
    return adjusted
  }

}
