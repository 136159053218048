export default class WclUtils {
  constructor() {
    this.counts = 0;
  }
  nowYear() {
    return moment().format("Y");
  }
  uniqueId() {
    return this.counts++;
  }
}
