export default class WclDatePicker {
  constructor() {
    this.locale = 'ja'
    this.momentParams = {
      months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
    }
    this.icons = {
      time: 'glyphicon glyphicon-time',
      date: 'glyphicon glyphicon-calendar',
      up: 'glyphicon glyphicon-chevron-up',
      down: 'glyphicon glyphicon-chevron-down',
      previous: 'glyphicon wcl-icon wcl-icon-chevron-left',
      next: 'glyphicon wcl-icon wcl-icon-chevron-right',
      today: 'glyphicon glyphicon-screenshot',
      clear: 'glyphicon glyphicon-trash',
      close: 'glyphicon glyphicon-remove'
    }
  }

  supplyModule() {
    // date-pickerを付与
    // TODO momentのlocal設定はどこか別の所に移す
    // moment.updateLocale(this.locale, this.momentParams)

    // 現状、previous/nextしか表示されないため、そこだけ変更する
    let dom = $('.datetimepicker-date.year')
    if (0 < dom.length) {
      // console.log({dom})
      dom.datetimepicker({
        format: "YYYY",
        locale: 'ja',
        viewMode: "years",
        icons: this.icons,
        // debug: true,
      });
    }
    dom = $('.datetimepicker-date.month')
    if (0 < dom.length) {
      // console.log({dom})
      dom.datetimepicker({
        format: "YYYY/MM",
        locale: 'ja',
        viewMode: "months",
        icons: this.icons,
        // debug: true,
      });
    }
    dom = $('.datetimepicker-date.day')
    if (0 < dom.length) {
      // console.log({dom})
      dom.datetimepicker({
        format: "YYYY/MM/DD",
        locale: 'ja',
        dayViewHeaderFormat: 'YYYY/MMMM',
        icons: this.icons,
        // debug: true,
      });
    }
  }

}
