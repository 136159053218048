
const debugPrint = () => {
  console.log("imported Wcl 1.0")
}
debugPrint()

import Wcl from "./Wcl"
import "./ajaxProvider"

var wcl = new Wcl()
window.wcl = wcl  // globalに持たせる

/// turbolinks 向け処理
// $(document).on('turbolinks:click', () => { console.log('turbolinks:click'); });
// $(document).on('turbolinks:before-visit', () => { console.log('turbolinks:before-visit'); });
// $(document).on('turbolinks:visit', () => { console.log('turbolinks:visit'); });
// $(document).on('turbolinks:request-start', () => { console.log('turbolinks:request-start'); });
// $(document).on('turbolinks:request-end', () => { console.log('turbolinks:request-end'); });
$(document).on('turbolinks:before-cache', (event) => {
  // console.log('turbolinks:before-cache');
  wcl.onBeforeCache({timing: 'turbolinks:before-cache', event});
});
// $(document).on('turbolinks:before-render', () => { console.log('turbolinks:before-render'); });
// $(document).on('turbolinks:render', () => { console.log('turbolinks:render'); });
$(document).on('turbolinks:load', (event) => {
  // console.log('turbolinks:load');
  wcl.onLoadPage({timing: 'turbolinks:load', event});
});
