import WclSelect2Wrapper from "./WclSelect2Wrapper"

export default class WclSelect {

  constructor(opts={}) {
  }

  supplyModule(selector) {
    // console.log("supplyModule", {selector, this: this});
    const wclSelect2Wrapperr = new WclSelect2Wrapper(selector);
    wclSelect2Wrapperr.init();
  }

  destroyModule(selector) {
    try {
      selector.select2('destroy');
    } catch(e) {
      // 適用されていない場合はエラーになるが、問題無いため握り潰す
    }
  }

}
