export default class WclTabList {

  constructor(opts={}) {
  }


  supplyModule(selector) {
    $(selector).on('shown.bs.tab', (e) => {
      console.log("shown.bs.tab");
      var activated_tab = e.target // activated tab
      var previous_tab = e.relatedTarget // previous tab
      wcl.onLoadPage({timing: "shown.bs.tab", event: e});
    })
  }
}
