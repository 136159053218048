export default class WclDateRangePicker {
  constructor() {

    this.formatYMD = 'YYYY/MM/DD'
    this.config = {
      showDropdowns: true,
      autoUpdateInput: false,
      dateLimit: {
          days: 60
      },
      ranges: {
        '今日': [moment(), moment()],
        '昨日': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '最新7日間': [moment().subtract(6, 'days'), moment()],
        '最新30日間': [moment().subtract(29, 'days'), moment()],
        '今月': [moment().startOf('month'), moment().endOf('month')],
        '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      alwaysShowCalendars: true,
      locale: {
        formatYMD: this.formatYMD,
        applyLabel: '確定',
        cancelLabel: 'クリア',
        fromLabel: 'From',
        toLabel: 'To',
        customRangeLabel: 'カスタム',
        daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        firstDay: 1
      }
    }
  }

  supplyModule() {
    // date-pickerを付与
    // TODO momentのlocal設定はどこか別の所に移す
    // moment.updateLocale(this.locale, this.momentParams)

    let selector = null
    selector = $('input.wcl-daterangepicker-single')
    // console.log({method: "WclDateRangePicker", selector})
    if (0 < selector.length) {
      selector.daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
      });
    }

    selector = $('input.wcl-daterangepicker-single-with-time')
    // console.log({method: "WclDateRangePicker", selector})
    if (0 < selector.length) {
      // console.log({method: "WclDateRangePicker", selector})
      let format = 'YYYY/MM/DD HH:mm:ss'
      selector.daterangepicker({
        locale: {
          format: format,
        },
        autoUpdateInput: false,
        singleDatePicker: true,
        showDropdowns: true,
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: true,
        ranges: {
          '現在': [moment()],
        }
      });
      $(selector).on('apply.daterangepicker', function(ev, picker) {
        // console.log("on apply.daterangepicker", {picker})
        $(this).val(picker.startDate.format(format));
      });

      $(selector).on('cancel.daterangepicker', function(ev, picker) {
        // console.log("on cancel.daterangepicker")
        $(this).val('');
      });
    }

    selector = $('input.wcl-daterangepicker-range')
    // console.log({method: "WclDateRangePicker", selector})
    if (0 < selector.length) {
      // console.log({selector})
      selector.daterangepicker(this.config);
      const self = this
      $(selector).on('apply.daterangepicker', function(ev, picker) {
        // console.log("on apply.daterangepicker")
        $(this).val(picker.startDate.format(self.formatYMD) + ' - ' + picker.endDate.format(self.formatYMD));
      });

      $(selector).on('cancel.daterangepicker', function(ev, picker) {
        // console.log("on cancel.daterangepicker")
        $(this).val('');
      });

    }

  }

}
