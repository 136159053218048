export default class WclFile {
  readImageFileList(fileList) {
    const promises = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i]
      // console.log("readImageFileList", {file});
      promises.push(stk.file.readImageFile(file));
    }
    return Promise.all(promises);
  }

  readImageFile(file) {
    return new Promise((resolve, reject) => {
      console.log("readImageFile", {file});
      if (
        !/\.(jpg|jpeg|png|gif|pdf|JPG|JPEG|PNG|GIF|PDF)$/.test(file.name)
        || !/(jpg|jpeg|png|gif|pdf)$/.test(file.type)
      ) {
        // ファイルタイプ
        return reject('JPG、GIF、PNG、PDFファイルを添付してください。');
      } else if ((5 * 1024 * 1024) < file.size) {
        // ファイルサイズ
        return reject('5MB以下のファイルを添付してください。');
      } else if (!window.FileReader) {
        // window.FileReaderに対応しているブラウザどうか
        return reject('最新のブラウザを使用してください。');
      }

      var reader = new FileReader();
      reader.onload = () => {
        return resolve({file: file, result: reader.result});
      }
      reader.readAsDataURL(file);
    });
  }

  readCsvFileList(fileList) {
    const promises = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i]
      // console.log("readImageFileList", {file});
      promises.push(stk.file.readCsvFile(file));
    }
    return Promise.all(promises);
  }

  readCsvFile(file) {
    return new Promise((resolve, reject) => {
      // console.log({file});
      if (!/\.(csv|CSV)$/.test(file.name)) {
        // ファイルタイプ
        // chrome では text/csv に対応していないため省く
        return reject('csvファイルを添付してください。');
      } else if ((10 * 1024 * 1024) < file.size) {
        // ファイルサイズ
        return reject('10MB以下のファイルを添付してください。');
      } else if (!window.FileReader) {
        // window.FileReaderに対応しているブラウザどうか
        return reject('最新のブラウザを使用してください。');
      }

      var reader = new FileReader();
      reader.onload = () => {
        return resolve({file: file, result: reader.result});
      }
      reader.readAsDataURL(file);
    });
  }

  createBlobFile(fileObjects, nameOfZipfile) {
    // このメソッドはzipを作成する可能性がある為 promise(filename, blob) を返却する
    // fileObjects のサイズが1の場合はfileを作成する
    // fileObjects のサイズが2以上の場合はzipFileを作成する

    if (2 <= fileObjects.length) {
      // ファイルが2以上の場合はzipを返却する
      var zip = new JSZip();
      fileObjects.forEach(fileObject => {
        zip.file(fileObject.filename, fileObject.content);
      });
      return zip.generateAsync({type: "blob"}).then(blob => {
        return {
          filename: nameOfZipfile,
          blob: blob,
        };
      });

    } else if (1 === fileObjects.length) {
      // 単体ファイル
      fileObject = fileObjects[0];
      // TODO BOM付きにした方がイイ？Excelではbom付きにすると開けない？
      // const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([fileObject.content], {"type": fileObject.type});
      return Promise.resolve({
        filename: fileObject.filename,
        blob: blob,
      });

    } else {
      // fileObjectsが0件
      // console.log("fileObjects.length zero");
      return Promise.resolve({
        filename: "",
        blob: null,
      });
    }
  }

  downloadBlob(filename, blob) {
    if (window.navigator.msSaveBlob) {
      // IE
      window.navigator.msSaveBlob(blob, filename);
      return true;

    } else if (window.URL && window.URL.createObjectURL) {
      // その他
      const tmpAElement = document.createElement("A");
      tmpAElement.download = filename;
      tmpAElement.href = window.URL.createObjectURL(blob);
      document.body.appendChild(tmpAElement);
      tmpAElement.click();
      document.body.removeChild(tmpAElement);
      return true;
    }
    console.log("error downloadBlob")
    return false;
  }
}
