export default class WclPicker {

  constructor(opts={}) {
  }

  supplyModule(selector) {
    // console.log("WclPicker", {selector})
    const wclPickerContainer = new WclPickerContainer(selector)
    wclPickerContainer.init();
    // window.test_picker = wclPickerContainer; // TODO
  }
}

class WclPickerContainer {
  constructor(selector) {
    this.selector = selector
    this.selecteds = []
  }

  getAttrs() {
    this.attrs = {
      multiple: this.selector.is('[data-multiple]'),
      closeModalOnSelect: this.selector.is('[data-close-on-select]'),
      pickerId: this.selector.attr('data-picker-id'),
      dataChangeValueTargetSelectorQuery: this.selector.attr('data-change-value-target-selector-query'),
      dataChangeElementTargetSelectorQuery: this.selector.attr('data-change-element-target-selector-query'),
      dataAddClassSelectedCloneElement: this.selector.attr('data-add-class-selected-clone-element'),
      dataInitialSelectedIds: this.selector.attr('data-initial-selected-ids-string').split(','),
    };
    // console.log({getAttrs: this.attrs})
  }

  worksWithModalController(containerSelector) {
    const modalControllerSelector = $(`div.wcl-picker-modal-controller[data-picker-id=${this.attrs.pickerId}]`)
    this.modalControllerSelector = modalControllerSelector
    // console.log({modalControllerSelector: this.modalControllerSelector})
    if (this.modalControllerSelector.length) {
      // console.log("worksWithModalController enabled")

      const modal = containerSelector.find(`.modal`)
      const modalOpenElement = modalControllerSelector.find(`.modal-open`)
      const modalContentLoadElement = modalControllerSelector.find(`.modal-content-load`)
      let modalInitialized = false

      // tableのリサイズ
      // modal非表示状態だとtableのレイアウトが崩れるため、表示時にリサイズを強制する
      // timeoutが表示より速い場合調整されないため、表示開始/表示完了の為2段階でやる
      modal.on('show.bs.modal', () => {
        setTimeout(() => {
          $(window).trigger('resize');
        }, 160);
      })
      modal.on('shown.bs.modal', () => {
        $(window).trigger('resize');
      })

      // select時にmodalを閉じる
      if (this.attrs.closeModalOnSelect) {
        window.wcl.event.on("wcl-picker::on_change", (opts) => {
          window.wcl.modal.close(modal)
        }, {key: this.attrs.pickerId})
      }

      modalOpenElement.on('click', () => {
        window.wcl.modal.open(modal)
        if (!modalInitialized) {
          // console.log("initialize", {modalContentLoadElement})
          modal.find(".overlay-spinner").css("display", "flex")
          modalInitialized = true
          window.wcl.event.one("wcl-ajax::on_complete", ({target}) => {
            // TODO 確実に描画が終わってから実行したい
            setTimeout(() => {
              // console.log("on-loaded-modal", modal)

              // オーバーレイを消去する
              modal.find(".overlay-spinner").css("display", "none")

              // 初期選択を適用する
              const items = modal.find(".wcl-picker-item")
              items.each((index, element) => {
                const _selector = $(element)
                if (this.attrs.dataInitialSelectedIds.includes(_selector.attr('data-item-value'))) {
                  this.updateSelected(_selector)
                }
              });
            }, 500)
          })
          window.wcl.html.fire(modalContentLoadElement)
        }
      })
    }
  }

  init() {
    this.getAttrs();
    this.worksWithModalController(this.selector);
    this.setOnClickFunction(this.selector);
  }

  setOnClickFunction(selector) {
    // 適用は1度のみで、コンテナの中身が変更される可能性があるため、eventはコンテナ自体に設定する
    selector.on("click", (ev) => {
      // console.log({target: ev.target})
      const pickerItem = $(ev.target).closest(".wcl-picker-item")
      if (pickerItem.length) {
        this.onClickPickerItem(pickerItem)
      }
    })
  }

  onClickPickerItem(selector) {
    // console.log("onClickPickerItem", {selector})
    this.updateSelected(selector)
    this.changeValue(selector)
    this.changeElement(selector)
    this.fireEventOnChange(selector)
  }

  fireEventOnChange(selector) {
    window.wcl.event.fire("wcl-picker::on_change", {selector})
  }

  updateSelected(selector) {
    const classSelected = "selected"
    if (this.attrs.multiple) {
      const index = this.selecteds.findIndex(selected => selected.is(selector))
      if (-1 === index) {
        this.selecteds.push(selector)
        selector.addClass(classSelected)
      } else {
        this.selecteds.splice(index, 1)
        selector.removeClass(classSelected)
      }
    } else {
      this.selecteds.forEach((item, i) => {
        item.removeClass(classSelected)
      });
      this.selecteds.length = 0
      this.selecteds.push(selector)
      selector.addClass(classSelected)
    }
    // console.log({selecteds: this.selecteds})
  }

  changeValue(srcSelector) {
    // console.log("changeValue", {srcSelector})
    const array = []
    this.selecteds.forEach((item, i) => {
      array.push(item.attr('data-item-value'))
    });

    const value = array.join(",")

    if (this.attrs.dataChangeValueTargetSelectorQuery) {
      $(`${this.attrs.dataChangeValueTargetSelectorQuery}`).each((index, target) => {
        // console.log({target, value})
        $(target).attr("value", value)
      })
    }
  }

  changeElement(srcSlector) {
    // console.log("changeElement", {srcSlector})

    const array = []
    this.selecteds.forEach((item, i) => {
      const pickableElement = $(item).find('.wcl-picker-pickable-element')
      var cloneElement = $(pickableElement).clone(false);
      cloneElement.removeAttr("onclick");
      cloneElement.removeClass("selected");
      cloneElement.addClass(this.attrs.dataAddClassSelectedCloneElement);
      array.push(cloneElement)
    });

    const element = array.join("")
    if (this.attrs.dataChangeElementTargetSelectorQuery) {
      $(`${this.attrs.dataChangeElementTargetSelectorQuery}`).each((index, target) => {
        // console.log({target, array})
        $(target).html(array)
      })
    }
  }

}
