export default class WclGlobalValue {
  constructor() {
    this.keyValue = null
  }

  getKeyValue() {
    if (!this.keyValue) {
      this.keyValue = $("#global-values").data('json');
    }
    return this.keyValue;
  }

  get(key) {
    return this.getKeyValue()[key];
  }

}
